/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable-next-line jsx-a11y/anchor-is-valid */
import React, { useState, memo, useEffect } from "react";
import { formatDocument } from "../../utils/string-utils";
import '@fortawesome/fontawesome-free/css/all.css';
import {
  arrumatexto,
  mat_fil_extenso,
  sit_cad_extenso,
  beneficios_cpf,
  pessoa_publica,
} from "../functions/formatos";
import Spinner from "../layout/Spinner";
import Loading from "../layout/Loading";
import states from "../../utils/states.json";
import sedeIcon from "../../img/icons/sede.svg";
import capitalIcon from "../../img/icons/capital-social.svg";
import contatoIcon from "../../img/icons/contato.svg";
import businessIcon from "../../img/icons/business.svg";
import socioIcon from "../../img/icons/socio.svg";
import CapitalIcon from "../../img/icons/capital.png";
import { FaWhatsapp } from 'react-icons/fa';




const CNPJDetailsFile = ({
  resultados_cnpj,
  socios_cnpj,
  isPopupLoading,
  verDetalhesSocios,
  google_info,
  verDetalhesCNPJ,
  resultados_leads,
  subsidiarias_holdings
}) => {
  //console.log("subsidiarias_holdings:", subsidiarias_holdings);
  //console.log("subsidiarias_holdings:", socios_cnpj);
  const [cnaes, setCnaes] = useState([]);
  const [cnaesLoaded, setCnaesLoaded] = useState([]);
  const [showGoogleAddress, setShowGoogleAddress] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullSpecialties, setshowFullSpecialties] = useState(false);

  // Other code...
  function isCellphone(phone) {
    // Regular expression to match a cellphone number format starting with "9"
    const cellphoneRegex = /^\(\d{2}\)9\d{8}$/;

    return cellphoneRegex.test(phone);
  }



  let cnae_secundario_array = [];
  let allCnaes;
  let cnae_primario = {};

  useEffect(() => {
    if (!resultados_cnpj) return;
    cnae_primario = {
      code: formatDocument(resultados_cnpj[0].cnae_primario),
      description: resultados_cnpj[0].nome,
    };
    if (resultados_cnpj[0].cnae_secundario_full) {
      const cnae_secundario_cods = resultados_cnpj[0].cnae_secundario_full
        .split("-")[0]
        .split(",");
      const cnae_secundario_descs = resultados_cnpj[0].cnae_secundario_full
        .split("-")[1]
        .split(",");
      for (let i = 0; i < cnae_secundario_cods.length; i++) {
        cnae_secundario_array[i] = {
          code: cnae_secundario_cods[i]?.trim(),
          description: cnae_secundario_descs[i]?.trim(),
        };
      }
      allCnaes = cnae_secundario_array;
      allCnaes.unshift(cnae_primario);
      setCnaesLoaded(allCnaes);
      setCnaes(allCnaes.slice(0, 2));
    } else if (cnae_primario) {
      setCnaes([cnae_primario]);
    } else {
      setCnaes([]);
    }
  }, [resultados_cnpj]);

  const {
    is_info_updated,
    updated_phone,
    updated_address,
    updated_url,
    updated_rating,
  } = google_info;
  //googlemaps
  const API_KEY = 'AIzaSyB8UBo9SAWyHSck6nzFyO1e1F3tlDmmaW0';
  const maps_endereco = resultados_cnpj?.[0]?.logradouro + ', ' + resultados_cnpj?.[0]?.numero;
  const zoom = '15';

  
  const ABOUT = resultados_cnpj?.[0]?.about;
  

//DISTINCT EMAILS
const EMAIL_BOX = resultados_cnpj?.[0]?.email;
const distinctEmails = EMAIL_BOX
  ? EMAIL_BOX
      .split(' ') // Split by delimiter if necessary (e.g., space)
      .filter(email => email) // Remove empty values
      .map(email => email.toLowerCase())
      .filter((value, index, self) => self.indexOf(value) === index)
  : [];
//

  const CNPJ_API = resultados_cnpj?.[0]?.cnpj;

  //console.log('CNPJ_API:', CNPJ_API);
  const fetch = require('node-fetch');
  //fim google maps
  let intervalId;
  //APIS
  const axios = require('axios');

  //API linkedin
  fetch("https://api.datafinder.com.br:7776/api/rest/scrapbycnpj/" + CNPJ_API, {
    method: "GET"
  })
    .then((response) => {
      if (response.ok) {
        return "OK";
      } else {
        // throw new Error("[LINKEDIN API] API call failed with status: " + response.status, CNPJ_API);
      }
    })
    .then((result) => {
      //  console.log("[LINKEDIN API] API call successful:", result, CNPJ_API);
    })
    .catch((error) => {
      //  console.error("[LINKEDIN API] API call failed:", error, CNPJ_API);
    });
  //FIM API linkedin



  const description = resultados_cnpj?.[0]?.about || '-';
  const truncatedDescription = showFullDescription
    ? description
    : description.slice(0, 120) + (description.length > 50 ? '...' : '');



  const especialidades = resultados_cnpj?.[0]?.specialties || '-';
  const truncatedSpecialties = showFullSpecialties
    ? especialidades
    : especialidades.slice(0, 50) + (especialidades.length > 50 ? '...' : '');


  return isPopupLoading ? (
    <Spinner style={{ position: "absolute" }} />
  ) : (
    resultados_cnpj && (
      <div className="details-container">
        <div className="details-icon">
          <div class="Icon">
            <img
              style={{ aspectRatio: 3 / 2 }}
              src={`data:image/jpeg;base64,${resultados_cnpj[0].logo_linkedin}`}
              className="esc-logo slide-top left"
              alt="logo"
              align="left"
            />
          </div>
        </div>
        <div className="details-title-container">
          <div className="details-title-razao-cnpj">
            <p className="details-title-razao">
              {arrumatexto(resultados_cnpj[0].razao_social)}
            </p>
            <p className="details-title-cnpj">
              {formatDocument(resultados_cnpj[0].cnpj)}
            </p>
            <div className="results-row-cnae-container4">
              <p className="cnae-title">
                {arrumatexto(resultados_cnpj[0].nome_fantasia) || " - "}
              </p>
              <div className="primary-cnpj_status primary-razao-cnpj"></div>
            </div>
            <div className="details-title-status">
              <span>
                {mat_fil_extenso(
                  resultados_cnpj[0].nm_matriz_filial.split(" ")[0]
                )}
              </span>
              <span>
                {sit_cad_extenso(resultados_cnpj[0].situacao_cadastral_nome)}
              </span>
            </div>
          </div>
          <div className="details-title-separator" />
          <div className="details-title-location">
            <p className="details-title-municipio">
              {arrumatexto(resultados_cnpj[0].municipio)}
            </p>
            <p className="details-title-uf">
              {arrumatexto(states[resultados_cnpj[0].uf].name)}
            </p>
            <div class="google-maps">
              <iframe
                title="Google Maps Embed"
                width="300"
                height="300"
                zoom="150"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${maps_endereco}&zoom=${zoom}`}
              ></iframe>
            </div>
          </div>
        </div>
        <section id="gigs">
          <div
            className="result_box"
            style={{ marginBottom: 0, overflow: "scroll" }}
          >
            <div style={{ maxHeight: "90vh", margin: 0 }}>
              <div className="details-inner-container">
                <div className="details-inner-left-container">


                  {/* DETALHES */}

                  <div className="details-left-border details-left-empresa">
                    <div className="details-left-title">
                      <h1>
                        Informações{" "}

                        <a href={resultados_cnpj[0].url_linkedin} target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-linkedin fa-1x"></i>
                        </a>{" "}
                        <a href={resultados_cnpj[0].instagramUrl} target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-instagram fa-1x"></i>
                        </a>{" "}
                        <a href={resultados_cnpj[0].facebookUrl} target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-facebook fa-1x"></i>
                        </a>
                        <a href={resultados_cnpj[0].youtubeUrl} target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-youtube fa-1x"></i>
                        </a>

                      </h1>
                    </div>

                    <div className="details-inner-left-description">
                      <div className="details-grid-five">
                        <span>
                          <p className="details-inner-left-description-title">Descrição</p>
                          <p className="details-inner-left-description-subtitle" style={{ textAlign: 'justify' }}>
                            {truncatedDescription}
                          </p>
                          {description.length > 50 && (
                            <button
                              className="show-more-button"
                              onClick={() => setShowFullDescription(!showFullDescription)}
                              style={{
                                borderRadius: '1rem',
                                padding: '0.2rem 0.5rem',
                                backgroundColor: '#4cc3db',
                                color: '#000000',
                                marginRight: '0.5rem',
                                borderColor: '#4cc3db',
                                boxShadow: showFullDescription ? '0px 2px 4px rgba(0, 0, 0, 0.3)' : '0px -2px 4px rgba(0, 0, 0, 0.3)'

                              }}
                            >
                              {showFullDescription ? '\u2212' : '\u002b'}
                            </button>
                          )}
                        </span>


                      </div>
                      <div className="details-grid-one">
                        <span>
                          <p className="details-inner-left-description-title">Abertura</p>
                          <p className="details-inner-left-description-subtitle">{resultados_cnpj[0].data_inicio || '-'}</p>
                        </span>
                        <span>
                          <p className="details-inner-left-description-title">Receita</p>
                          <p className="details-inner-left-description-subtitle">{resultados_cnpj[0].receita || '-'}</p>
                        </span>
                        <span>
                          <p className="details-inner-left-description-title">Funcionários</p>
                          <p className="details-inner-left-description-subtitle">{resultados_cnpj[0].company_size || '-'}</p>
                        </span>
                        <span>
                          <p className="details-inner-left-description-title">Atuação</p>
                          <p className="details-inner-left-description-subtitle">{arrumatexto(resultados_cnpj[0].sphere) || '-'}</p>
                        </span>
                      </div>
                      <div className="details-grid-five">
                        <span>
                          <p className="details-inner-left-description-title">Especialidades</p>
                          <p className="details-inner-left-description-subtitle" style={{ textAlign: 'justify' }}>
                            {truncatedSpecialties}
                          </p>
                          {especialidades.length > 50 && (
                            <button
                              className="show-more-button"
                              onClick={() => setshowFullSpecialties(!showFullSpecialties)}
                              style={{
                                borderRadius: '1rem',
                                padding: '0.2rem 0.5rem',
                                backgroundColor: '#4cc3db',
                                color: '#000000',
                                borderColor: '#4cc3db',
                                boxShadow: showFullSpecialties ? '0px 2px 4px rgba(0, 0, 0, 0.3)' : '0px -2px 4px rgba(0, 0, 0, 0.3)'
                              }}
                            >
                              {showFullSpecialties ? '\u2212' : '\u002b'}
                            </button>
                          )}
                        </span>
                      </div>
                      <div className="details-grid-two">
                        <span>
                          <p className="details-inner-left-description-title">Porte</p>
                          <p className="details-inner-left-description-subtitle">{arrumatexto(resultados_cnpj[0].porte) || '-'}</p>
                        </span>
                        <span>
                          <p className="details-inner-left-description-title">MEI</p>
                          <p className="details-inner-left-description-subtitle">{resultados_cnpj[0].microempreendedor_individual || '-'}</p>
                        </span>
                        <span>
                          <p className="details-inner-left-description-title">Simples</p>
                          <p className="details-inner-left-description-subtitle">{arrumatexto(resultados_cnpj[0].simples_nacional) || '-'}</p>
                        </span>
                        <span>
                          <p className="details-inner-left-description-title">Processos Civis</p>
                          <p className="details-inner-left-description-subtitle">
                            {resultados_cnpj[0].quantidade_processos !== undefined && resultados_cnpj[0].quantidade_processos !== null
                              ? resultados_cnpj[0].quantidade_processos
                              : '0'}
                          </p>
                        </span>

                      </div>
                    </div>
                  </div>

                  {/*FIM DETALHES */}

                  {/* CONTATOS */}

                  <div className="details-left-border details-left-contato">
                    <div className="details-left-title">
                      <h1>Contato</h1>
                    </div>
                    <img src={contatoIcon} alt="Contato" className="details-left-icon" />
                    <div className="details-inner-left-description">
                      <div className="details-grid-three">
                        <span>
                          <p className="details-inner-left-description-title">Telefone</p>
                          <span className="details-inner-left-description-subtitle details-phone">
                            {!is_info_updated ? (
                              <p>
                                Atualizando... <Loading />
                              </p>
                            ) : updated_phone === "Not found" ? null : (
                              <>
                                {updated_phone.split(',').map((phone, index) => {
                                  const formattedPhone = `55${phone.replace(/[\\(\\)\\-]/g, '')}`; // Add "55" before the phone number in the link
                                  return (
                                    <React.Fragment key={index}>
                                      {index > 0 && <br />}
                                      {isCellphone(phone) ? (
                                        <>
                                          <a href={`https://api.whatsapp.com/send?phone=${formattedPhone}`} target="_blank" rel="noopener noreferrer">
                                            {phone} <FaWhatsapp />
                                          </a>
                                          <br />
                                        </>
                                      ) : (
                                        phone
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </>
                            )}
                          </span>

                        </span>
                        <span></span>
                        <span>
  <p className="details-inner-left-description-title">E-mail</p>
  {distinctEmails.length > 0 ? (
    distinctEmails.map((email, index) => (
      <p className="details-inner-left-description-subtitle" key={index}>
        {email}
      </p>
    ))
  ) : (
    <p className="details-inner-left-description-subtitle">-</p>
  )}
</span>

                        <span>
                          <p className="details-inner-left-description-title">Site</p>
                          <p className="details-inner-left-description-subtitle" style={{ wordBreak: "break-all" }}>
                            {!is_info_updated ? (
                              <Loading />
                            ) : updated_url === "Not found" ? (
                              <p>{resultados_cnpj[0].website}</p>
                            ) : (
                              <a href={updated_url} target="_blank" rel="noreferrer">
                                {updated_url}
                              </a>
                            )}
                          </p>
                        </span>
                      </div>
                      <div className="details-grid-four">
                        <span>
                          <p className="details-inner-left-description-title">Endereço</p>
                          <p className="details-inner-left-description-subtitle">
                            {arrumatexto(resultados_cnpj[0].descricao_tipo_logradouro)}{" "}
                            {arrumatexto(resultados_cnpj[0].logradouro)}, {resultados_cnpj[0].numero}, Bairro{" "}
                            {arrumatexto(resultados_cnpj[0].bairro)}, CEP - {formatDocument(resultados_cnpj[0].cep)},{" "}
                            {resultados_cnpj[0].municipio} - {resultados_cnpj[0].uf}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* FIM CONTATOS */}



                {/* CNAES */}
                <div className="details-inner-right-container">
                  <div className="details-inner-right-upper">


                    {/* CNAES */}
                    <div className="details-cnaes">
                      <h1 className="details-cnaes-title">CNAEs</h1>
                      <img src={businessIcon} alt="Negócios" className="details-right-business-icon" />
                      <div className="details-cnaes-map-container">
                        {cnaes && cnaes.length > 0 && cnaes.map((cnae, index) => (
                          <div className="details-cnae-container" key={index}>
                            <p>
                              <span className="details-cnae-index">{index + 1}</span>
                              <span className="details-cnaes-code">{cnae.code}</span>
                            </p>
                            <p className="details-cnae-description">{cnae.description}</p>
                          </div>
                        ))}
                      </div>
                      {cnaes.length > 2 && (
                        <div className="details-cnae-button" onClick={() => setCnaes(cnaesLoaded && cnaesLoaded.slice(0, 2))}>
                          {cnaes.length < 3 ? 'Ver mais' : 'Ver menos'}
                          <span className="details-cnae-index">{cnaes.length < 3 ? '+' : '-'}</span>
                        </div>
                      )}
                    </div>
                    {/* FIM CNAES */}

                    <div className="details-sede-capital">
                      {/* Caixa de filiais*/}
                      {resultados_cnpj[0].filiais && resultados_cnpj[0].filiais !== 0 ? (
                        <div className="details-sede-container">
                          <div className="details-sede-icon">
                            <img src={sedeIcon} alt="Prédio" />
                          </div>
                          <div className="details-sede-description">
                            <h3>Filiais</h3>
                            <h2>{resultados_cnpj[0].filiais}</h2>
                          </div>
                        </div>
                      ) : null}

                      {/* FIM Caixa de filiais*/}

                      {/* Caixa de capital*/}
                      {resultados_cnpj[0].capital && resultados_cnpj[0].capital !== 0 ? (
                        <div className="details-capital-container">
                          <div className="details-capital-icon">
                            <img src={capitalIcon} alt="Sifrão" />
                          </div>
                          <div className="details-capital-description">
                            <h3>Capital Social</h3>
                            <h2>
                              {resultados_cnpj[0].capital.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }) || "-"}
                            </h2>
                          </div>
                        </div>
                      ) : null}

                      {/*FIM Caixa de capital*/}


                      {/*Caixa de relacoes com governo federal */}
                      <div>
                        {resultados_cnpj[0].qtd_gov_nf_matriz && resultados_cnpj[0].qtd_gov_nf_matriz !== 0 ? (
                          <div className="details-nf-container">
                            <div className="details-nf-icon">
                              <img src={CapitalIcon} alt="Congresso" />
                            </div>
                            <div className="details-nf-description">
                              <h3>Vendas ao Governo Federal</h3>
                              <h2>CNPJ RAIZ</h2>
                              <h3>Total NFs Emitidas: {resultados_cnpj[0].qtd_gov_nf_matriz}</h3>
                              <h3>Total Itens: {resultados_cnpj[0].qtd_gov_itens_matriz}</h3>
                              <h3>Valor Total: {resultados_cnpj[0].valor_total_gov_nf_matriz?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) || "-"}</h3>
                              <h2>Este CNPJ</h2>
                              <h3>Total NFs Emitidas: {resultados_cnpj[0].qtd_gov_nf_filial}</h3>
                              <h3>Total Itens: {resultados_cnpj[0].qtd_gov_itens_filial}</h3>
                              <h3>Valor Total: {resultados_cnpj[0].valor_total_gov_nf_filial?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) || "-"}</h3>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {/* FIM Caixa de relacoes com governo federal */}

                    </div>
                  </div>


                  {/* SCOIOS */}
                  <div className="details-inner-right-lower">
                    <h1>Sócios</h1>
                    <img src={socioIcon} alt="Sócios" className="details-right-socios-icon" />
                    <div className="details-socios-container">
                      {socios_cnpj.map((socio, index) => {
                        if (socio.identificador_socio === 1) {
                          return (
                            <div
                              className="details-socio-container"
                              onClick={() => {
                                verDetalhesSocios(socio.nome_socio, socio.cnpj_cpf_socio);
                              }}
                              key={index}
                            >
                              {/* Render your component for identificador_socio equal to 1 */}
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="details-socio-container"
                              onClick={() => {
                                verDetalhesSocios(socio.nome_socio, socio.cnpj_cpf_socio);
                              }}
                              key={index}
                            >
                              <div className="details-socio-decorator" />
                              <div className="details-socio-content">
                                <h1 className="details-socio-name">
                                  {arrumatexto(socio.nome_socio)}{" "}
                                  <span style={{ marginLeft: "0.3rem", color: "red" }}>
                                    {pessoa_publica(socio.pessoa_publica)}{" "}
                                  </span>
                                </h1>
                                <div className="details-socio-info-container">
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                      maxWidth: "12rem",
                                    }}
                                  >
                                    {arrumatexto(
                                      socio.nm_qualificacao_responsavel_socio || socio.razao_social
                                    )}
                                  </span>
                                  <span className="details-socio-separator">/</span>
                                  <span>
                                    {socio.cnpj_cpf_socio || socio.cnpj_full}
                                  </span>
                                  <span className="details-socio-separator">/</span>
                                  <span>
                                    {socio.data_entrada_sociedade &&
                                      `desde ${socio.data_entrada_sociedade}`}
                                  </span>
                                  <span style={{ marginLeft: "0.3rem" }}>
                                    ({socio.tempo_sociedade || socio.age} anos)
                                  </span>
                                  <span style={{ marginLeft: "0.3rem", color: "red" }}>
                                    {beneficios_cpf(socio.beneficiado)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                      {/* Add a condition to display a message if there are no socios */}
                      {socios_cnpj.length === 0 && (
                        <div>No socios found.</div>
                      )}
                    </div>
                  </div>

                  {/* FIM SCOIOS */}
                  {/* LEADS */}
                  <div>
                    {resultados_leads && resultados_leads.length > 0 && resultados_leads !== "error_leads" ? (
                      <div className="details-inner-right-lower">
                        <h1>Outros Contatos</h1>
                        <div className="details-leads-container">
                          {resultados_leads.map((lead, index) => {
                            return (
                              <div className="details-lead-container" key={index}>
                                <div className={"details-leads-decorator"} />
                                <div className={"details-leads-content"}>
                                  <h1 className={"details-leads-name"}>
                                    {arrumatexto(lead.name)}
                                  </h1>
                                  <span className="lead-detail">Cargo: {lead.position}</span>
                                  <span className="lead-detail">Email: {lead.email}</span>
                                  {lead.twitter && (
                                    <span className="lead-detail">Twitter: {lead.twitter}</span>
                                  )}
                                  {lead.department && (
                                    <span className="lead-detail">
                                      Departamento: {lead.department}
                                    </span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="no-results-message">
                        No results found. Please refine your search.
                      </div>
                    )}
                  </div>
                  {/* FIM LEADS */}


                  {/* SUBSIDIARIAS */}
                  <div>
                    {subsidiarias_holdings && subsidiarias_holdings.length > 0 && (
                      <div>
                        <h1>Subsidiarias</h1>
                        {subsidiarias_holdings.map((Subsidiarias, index) => (
                          <div
                            className="details-socio-container"
                            onClick={() => {
                              verDetalhesCNPJ(Subsidiarias.cnpj_sub);
                            }}
                            key={index}
                          >
                            <div className="details-holdings-decorator" />
                            <div className="details-holdings-content">
                              <h1 className="details-holdings-name" style={{ color: "black" }}>
                                {arrumatexto(Subsidiarias.razao_social)}
                              </h1>
                              <div className="details-holdings-info-container">
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                    maxWidth: "12rem",
                                    color: "black",
                                  }}
                                ></span>
                                <span className="details-holdings-separator"></span>
                                <span style={{ color: "black" }}>
                                  {formatDocument(Subsidiarias.cnpj_sub) || "-"}
                                </span>
                                <span className="details-holdings-separator"></span>
                                <span style={{ marginLeft: "0.3rem", color: "black" }}>
                                  Tempo de Sociedade: {Subsidiarias.tempo_socio} anos
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {/* FIM SUBSIDIARIAS */}
                </div>



              </div>
            </div>
          </div>
        </section>
      </div>
    )
  );
};
export default memo(CNPJDetailsFile); 