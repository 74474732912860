/* eslint-disable import/no-anonymous-default-export */
import { DADOS_CNPJ_IN, DADOS_CNPJ_OUT } from "../actions/types";

const initialState = {
  resultados_cnpj: null,
  socios_cnpj: null,
  subsidiarias_holdings: null, // Add the initial state for subsidiarias_holdings
  carregando: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DADOS_CNPJ_IN:
      return {
        ...state,
        resultados_cnpj: payload.resultados_cnpj,
        socios_cnpj: payload.socios_cnpj,
        subsidiarias_holdings: payload.subsidiarias_holdings, // Update the state with subsidiarias_holdings
        carregando: false,
      };
    case DADOS_CNPJ_OUT:
      return {
        ...state,
        resultados_cnpj: null,
        socios_cnpj: null,
        subsidiarias_holdings: null, // Reset the state for subsidiarias_holdings
        carregando: false,
      };

    default:
      return state;
  }
}
