export const SET_ALERT = "SET_ALERT"
export const REMOVE_ALERT = "REMOVE_ALERT"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const REGISTER_TRIAL_SUCCESS = "REGISTER_TRIAL_SUCCESS"
export const REGISTER_TRIAL_FAIL = "REGISTER_TRIAL_FAIL"
export const USER_LOADED = "USER_LOADED"
export const AUTH_ERROR = "AUTH_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"
export const GET_DETALHE = "GET_DETALHE"
export const DETALHE_ERROR = "DETALHE_ERROR"
export const DETALHE_OUT = "DETALHE_OUT"
export const DADOS_IN = "DADOS_IN"
export const DADOS_SEARCHING = "DADOS_SEARCHING"
export const DADOS_OUT = "DADOS_OUT"
export const DADOS_SOCIOS_IN = "DADOS_SOCIOS_IN"
export const DADOS_SOCIOS_OUT = "DADOS_SOCIOS_OUT"
export const DADOS_DIVIDAS_IN = "DADOS_DIVIDAS_IN"
export const DADOS_DIVIDAS_OUT = "DADOS_DIVIDAS_OUT"
export const DADOS_NCM_IN = "DADOS_NCM_IN"
export const DADOS_NCM_OUT = "DADOS_NCM_OUT"
export const DADOS_HOLDINGS_IN = "DADOS_HOLDINGS_IN"
export const DADOS_HOLDINGS_OUT = "DADOS_HOLDINGS_OUT"
export const DADOS_CNPJ_IN = "DADOS_CNPJ_IN"
export const DADOS_CNPJ_OUT = "DADOS_CNPJ_OUT"
export const DADOS_SOCIOS_NEW_IN = "DADOS_SOCIOS_NEW_IN"
export const DADOS_SOCIOS_NEW_OUT = "DADOS_SOCIOS_NEW_OUT"
export const DADOS_ECA_IN = "DADOS_ECA_IN"
export const DADOS_ECA_OUT = "DADOS_ECA_OUT"
export const DADOS_CNAE_IN = "DADOS_CNAE_IN"
export const DADOS_CNAE_OUT = "DADOS_CNAE_OUT"
export const DADOS_MEI_IN = "DADOS_MEI_IN"
export const DADOS_MEI_OUT = "DADOS_MEI_OUT"
export const FETCH_GOOGLE_IN = "FETCH_GOOGLE_IN"
export const FETCH_GOOGLE_OUT = "FETCH_GOOGLE_OUT"
export const DADOS_GEOM_IN = "DADOS_GEOM_IN"
export const DADOS_GEOM_OUT = "DADOS_GEOM_OUT"
export const FETCH_GEO_IN = "FETCH_GEO_IN"
export const FETCH_GEO_OUT = "FETCH_GEO_OUT"
export const DADOS_DASH_IN = "DADOS_DASH_IN"
export const DADOS_DASH_OUT = "DADOS_DASH_OUT"
export const DADOS_LEADS_SUCCESS = "DADOS_LEADS_SUCCESS"
export const DADOS_LEADS_FAILURE = "DADOS_LEADS_FAILURE"
export const LGPD = "LGPC"