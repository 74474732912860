import React from 'react'

export const Footer = () => {
    return (
<footer>
    <div className="footer-content">
        DataFinder Ltda™ © 2023 - Todos os direitos reservados
    </div>
</footer>
    )
}
