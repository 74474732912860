import axios from "axios";
import { DADOS_DIVIDAS_IN, DADOS_DIVIDAS_OUT } from "../actions/types";

export const buscarDetalhesDividas = (cnpj) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const userEmail = localStorage.getItem('userEmail'); // Get userEmail from localStorage
    console.log('Logged User:', userEmail); // Get userEmail from localStorage

    const body = null;

    // Include userEmail in the request body
    const res = await axios.get("/search/dividas/" + cnpj, {
      ...body,
      params: {
        userEmail: userEmail
      },
      ...config
    });

    dispatch({
      type: DADOS_DIVIDAS_IN,
      payload: res.data.respostas
    });
  } catch (error) {
    dispatch({
      type: DADOS_DIVIDAS_OUT
    });

    console.log(error);
  }
};
