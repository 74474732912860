/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import { Redirect } from "react-router-dom";
import Navbar from "../layout/Navbar";
import { Input, ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import PropTypes from "prop-types";

export const Registrar = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    senha: "",
    confirmar_senha: ""
  });

  const { nome, email, senha, confirmar_senha } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (senha !== confirmar_senha) {
      setAlert("As senhas não coincidem!", "danger");
    } else {
      register({ nome, email, senha });
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/principal" />;
  }

  return (
    <ConfigProvider locale={ptBR}>
      <div className="auth_container">
        <Navbar />
        <div className="auth_inner-container">
          <div className="auth_input-box">
            <h3 className="auth_title">Cadastrar Usuário</h3>
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <div className="auth_form-field">
                  <label htmlFor="nome">Nome</label>
                  <Input
                    type="text"
                    className="input-box"
                    name="nome"
                    id="nome"
                    value={nome}
                    allowClear
                    onChange={onChange}
                    placeholder="Digite seu nome"
                    required
                    style={{ color: "black" }}
                  />
                </div>

                <div className="auth_form-field">
                  <label htmlFor="email">Email</label>
                  <Input
                    type="email"
                    className="input-box"
                    name="email"
                    id="email"
                    value={email}
                    allowClear
                    onChange={onChange}
                    placeholder="Digite seu email"
                    required
                    style={{ color: "black" }}
                  />
                </div>
                <div className="auth_form-field">
                  <label htmlFor="senha">Senha</label>
                  <Input
                    type="password"
                    className="input-box"
                    name="senha"
                    id="senha"
                    value={senha}
                    allowClear
                    onChange={onChange}
                    placeholder="Digite sua senha"
                    required
                    style={{ color: "black" }}
                  />
                </div>
                <div className="auth_form-field">
                  <label htmlFor="confirmar_senha">Confirmar senha</label>
                  <Input
                    type="password"
                    className="input-box"
                    name="confirmar_senha"
                    id="confirmar_senha"
                    value={confirmar_senha}
                    allowClear
                    onChange={onChange}
                    placeholder="Confirme sua senha"
                    required
                    style={{ color: "black" }}
                  />
                </div>
              </div>

              <button type="submit" className="auth_btn">
                ENTRAR
              </button>
            </form>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

Registrar.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, register })(Registrar);
