import axios from "axios"
import { DADOS_LEADS_FAILURE, DADOS_LEADS_SUCCESS } from "./types"

// Busca na API de Leads
export const buscarLeads = (domain) => async (dispatch) => {
    try {
      if (domain && domain.includes(".")) {
        const config = {
          headers: {
            "Content-Type": "application/json"
          }
        };
  
        const response = await axios.get("/search/leads/" + domain, config);
        dispatch({
          type: DADOS_LEADS_SUCCESS,
          payload: response.data
        });
      } else {
        dispatch({
          type: DADOS_LEADS_SUCCESS,
          payload: null
        });
      }
    } catch (error) {
      dispatch({
        type: DADOS_LEADS_FAILURE
      });
    }
  };
  