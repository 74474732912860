import React, { memo, useEffect, useState } from "react";
import states from "../../utils/states.json";
import axios from "axios";
import { Select, Input, ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { arrumatexto } from "../functions/formatos";
import { tagRender } from "../../utils/tag-render";
import SmoothCollapse from "react-smooth-collapse";

const cache = {};

const getResult = async (path) => {
  try {
    if (cache[path]) return cache[path];
    const { data } = await axios.get(path);
    cache[path] = data;
    return data;
  } catch (e) {
    console.error(`Loading error path [${path}]`, e);
    return [];
  }
};

const CNPJFilterFile = ({ formData, setFormData, isMinimum, showMinimum }) => {
  const [cnaes, setCnaes] = useState([]);
  const [situacoesCadastrais, setSituacoesCadastrais] = useState([]);
  const [porteEmpresas, setPorteEmpresas] = useState([]);
  const [CompanySize, setComp_size] = useState([]);
  const [cnaesporclasse, setcnaesporclasse] = useState([]);
  const [natureza_juridica, setNatureza_jur] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [isLoadingCities, setLoadingCities] = useState(false);
  const [isLoadingcnaesporclasse, setLoadingcnaesporclasse] = useState(false);
  const [classecnae, setclassecnae] = useState([]);
  const [optionalsExpanded, setOptionalsExpanded] = useState(false);

  useEffect(() => {
    let isMounted = true; // Variable to track component mount/unmount

    (async () => {
      const [cnaesRes, porEmp, classeCnae, natjur, comp_size] = await Promise.all([
        getResult("/cnaes"),
        getResult("/porte-empresas"),
        getResult("/classecnae"),
        getResult("/natureza_juridica"),
        getResult("/company_size"),
      ]);

      if (isMounted) {
        setCnaes(cnaesRes);
        setPorteEmpresas(porEmp);
        setclassecnae(classeCnae);
        setNatureza_jur(natjur);
        setComp_size(comp_size);
      }
    })();

    // Cleanup function to cancel tasks when the component unmounts
    return () => {
      isMounted = false; // Mark the component as unmounted
    };
  }, []);

  const loadCidades = async (e) => {
    if (e !== "") {
      setFormData({
        ...formData,
        uf: e,
        municipio: [],
      });
      setLoadingCities(true);
      const result = await getResult(`/cidades-por-estado/${e}`);
      setCidades(result);
      setLoadingCities(false);
    } else {
      setCidades([]);
      setFormData({
        ...formData,
        uf: "",
        municipio: [],
      });
    }
  };

  const { Option } = Select;

  const fieldFilter = (input, option) =>
    option.children &&
    option.children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
      input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    );

  const ufFilter = (input, option) =>
    option.children &&
    option.children[0].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(
      input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    );

  const idadeEmpresaOptions = Array.from({ length: 10 }, (_, i) => ({
    value: (i + 1) * 10,
    label: `${(i + 1) * 10} anos`,
  }));

  return (
    <ConfigProvider locale={ptBR}>
      <div>
        {!isMinimum ? (
          <div style={{ fontSize: 10, color: showMinimum ? "red" : "gray", height: "15px" }}>
            *Preencha ao menos um campo obrigatório
          </div>
        ) : (
          <div style={{ height: "15px" }}></div>
        )}
        <div className="form-group">
          <h3 className="titulo_h3" style={showMinimum ? { color: "red" } : { color: "black" }}>
            {`Razão Social${!isMinimum ? "*" : ""}`}
          </h3>
          <Input
            className="largura_select"
            name="razao_social"
            minLength={3}
            value={formData.razao_social}
            allowClear
            onChange={(e) => setFormData({ ...formData, razao_social: e.target.value })}
          />
        </div>
        <div className="form-group">
          <h3 className="titulo_h3" style={showMinimum ? { color: "red" } : { color: "black" }}>
            {`Nome Fantasia${!isMinimum ? "*" : ""}`}
          </h3>
          <Input
            className="largura_select"
            name="nome_fantasia"
            minLength={3}
            value={formData.nome_fantasia}
            allowClear
            onChange={(e) => setFormData({ ...formData, nome_fantasia: e.target.value })}
          />
        </div>
        <div className="form-group">
          <h3 className="titulo_h3" style={showMinimum ? { color: "red" } : { color: "black" }}>
            {`CNPJ${!isMinimum ? "*" : ""}`}
          </h3>
          <Input
            className="largura_select"
            name="cnpj"
            minLength={8}
            maxLength={18}
            defaultValue=""
            value={formData.cnpj}
            allowClear
            onChange={(e) => setFormData({ ...formData, cnpj: e.target.value })}
          />
        </div>

        <div className="form-group">
          <h3 className="titulo_h3">Tamanho da Empresa</h3>
          <Select
            mode="multiple"
            showArrow
            allowClear
            tagRender={tagRender}
            style={{ width: "100%" }}
            name="company_size"
            onChange={(e) => {
              setFormData({ ...formData, company_size: e });
            }}
            showSearch
            optionFilterProp="children"
            filterOption={fieldFilter}
          >
            {CompanySize.map((c) => (
              <Option key={c.id} value={c.size}>
                {arrumatexto(c.size)}
              </Option>
            ))}
          </Select>
        </div>

        <SmoothCollapse expanded={optionalsExpanded}>
          <div className="form-group">
            <h3 className="titulo_h3">Porte da Empresa</h3>
            <Select
              mode="multiple"
              showArrow
              allowClear
              tagRender={tagRender}
              style={{ width: "100%" }}
              name="porte_empresa"
              onChange={(e) => {
                setFormData({ ...formData, porte_empresa: e });
              }}
              showSearch
              optionFilterProp="children"
              filterOption={fieldFilter}
            >
              {porteEmpresas.map((c) => (
                <Option key={c.id} value={c.id}>
                  {arrumatexto(c.nm_porte)}
                </Option>
              ))}
            </Select>
          </div>

          <div className="form-group">
            <h3 className="titulo_h3">MEI</h3>
            <Select
              allowClear
              style={{ width: "100%" }}
              name="opcao_pelo_mei"
              value={formData.opcao_pelo_mei}
              onChange={(e) => setFormData({ ...formData, opcao_pelo_mei: e })}
              showSearch
              optionFilterProp="children"
              filterOption={fieldFilter}
            >
              <Option value=""></Option>
              <Option value="s">Sim</Option>
              <Option value="n">Não</Option>
            </Select>
          </div>

          <div className="form-group">
            <h3 className="titulo_h3">Natureza Juridica</h3>
            <Select
              allowClear
              maxTagTextLength={22}
              mode="multiple"
              showArrow
              tagRender={tagRender}
              style={{ width: "100%" }}
              name="natureza_juridica"
              onChange={(e) => {
                setFormData({ ...formData, natureza_juridica: e });
              }}
              showSearch
              optionFilterProp="children"
              filterOption={fieldFilter}
            >
              {natureza_juridica.map((c) => (
                <Option key={c.cod_subclass_natureza_juridica} value={c.cod_subclass_natureza_juridica}>
                  {arrumatexto(c.nm_subclass_natureza_juridica)}
                </Option>
              ))}
            </Select>
          </div>

          {/* Filtro de Idade */}
          <div className="form-group">
            <h3 className="titulo_h3">Idade Empresa maior que:</h3>
            <Select
              allowClear
              style={{ width: "100%" }}
              name="idade_empresa_maior"
              value={formData.idade_empresa_maior}
              onChange={(e) => setFormData({ ...formData, idade_empresa_maior: e })}
              showSearch
              optionFilterProp="children"
              filterOption={fieldFilter}
            >
              <Option value=""></Option>
              {idadeEmpresaOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>

          {/* Filtro de UF */}
          <div className="form-group">
            <h3 className="titulo_h3">Pesquisa UF</h3>
            <Select
              style={{ width: "100%" }}
              allowClear
              name="uf"
              value={formData.uf}
              onChange={(e) => {
                e ? loadCidades(e) : setFormData({ ...formData, uf: "" });
              }}
              showSearch
              optionFilterProp="children"
              filterOption={ufFilter}
            >
              <Option value=""></Option>
              {Object.keys(states).map((acronym) => (
                <Option key={acronym} value={acronym}>
                  {arrumatexto(states[acronym].name)}
                </Option>
              ))}
            </Select>
          </div>

          {/* Filtro de Municipios */}
          <div className="form-group">
            <h3 className="titulo_h3">Município</h3>
            <Select
              mode="multiple"
              allowClear
              showArrow
              tagRender={tagRender}
              style={{ width: "100%" }}
              name="municipio"
              onChange={(e) => {
                setFormData({ ...formData, municipio: e });
              }}
              value={formData.municipio}
              disabled={isLoadingCities}
              showSearch
              optionFilterProp="children"
              filterOption={fieldFilter}
            >
              {cidades.map((c, index) =>
                c.cod_tom ? (
                  <Option key={c.cod_tom + index} value={c.cod_tom}>
                    {arrumatexto(c.nome)}
                  </Option>
                ) : null
              )}
            </Select>
          </div>
        </SmoothCollapse>

        <div className="btn btn-reverse buscar_btn" onClick={() => setOptionalsExpanded(!optionalsExpanded)}>
          <span>{optionalsExpanded ? "Retrair campos" : "Expandir mais campos"}</span>
        </div>
        
      </div>
    </ConfigProvider>
  );
};

export default memo(CNPJFilterFile);
