import axios from "axios";
import { DADOS_CNPJ_IN, DADOS_CNPJ_OUT } from "../actions/types";

export const buscarDetalhesCNPJ = (cnpj, nome_fantasia) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const userEmail = localStorage.getItem('userEmail'); // Get userEmail from localStorage
    console.log('Logged User:',userEmail)// Get userEmail from localStorage
    const queryParam = nome_fantasia ? `?nome_fantasia=${nome_fantasia}` : '';
    const body = null;

    // Include userEmail in the request body
    const res = await axios.get("/search/cnpj/" + cnpj + queryParam, {
      ...body,
      params: {
        userEmail: userEmail
      },
      ...config
    });

    dispatch({
      type: DADOS_CNPJ_IN,
      payload: res.data.respostas
    });
  } catch (error) {
    dispatch({
      type: DADOS_CNPJ_OUT
    });

    console.log(error);
  }
};
