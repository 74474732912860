import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import logo from "../../img/icons/logo.svg";
import ReactGA from 'react-ga';
import OutsideClickHandler from "../../utils/OutsideClickHandler";
ReactGA.initialize('G-JVCPL13LBW');

const Navbar = props => {
  const {
    auth: { isAuthenticated, loading, user },
    logout,
    activeCategory,
    visibilidadeCampos,
  } = props;

  const [menuMobileOpen, setMenuMobileOpen] = useState(false);

  const onMenuItemClicked = (item) => {
    setMenuMobileOpen(false);
    visibilidadeCampos(item);
    const body = document.getElementsByTagName('body');
    if (body && body.length) {
      body[0].scrollIntoView({ behavior: "smooth" });
    }
  };

  let mostrar = "";

  const navLinks = [
    {
      title: "todas empresas",
      tipo: "CNPJ",
    },
    {
      title: "holdings",
      tipo: "HOLDINGS",
    },
    {
      title: "cnae fiscal",
      tipo: "cnae",
    },
    {
      title: "sócios",
      tipo: "SOCIOS",
    },
    {
      title: "dívidas ativas",
      tipo: "Dividas",
    },
    {
      title: "inteligência fiscal",
      tipo: "NCM",
    },
    {
      title: "GEO Marketing",
      tipo: "GEOM",
    },
  ];

  useEffect(() => {
    if (user && user[0]?.nome) {
      localStorage.setItem('userName', user[0].nome);
    } else {
      localStorage.removeItem('userName');
    }

    if (user && user[0]?.email) {
      localStorage.setItem('userEmail', user[0].email);
    } else {
      localStorage.removeItem('userEmail');
    }
  }, [user]);

  const logados = (
    <>
      <ul className="navlinks-primary__container">
        {navLinks.map(navLink => {
          return (
            <li className="navlinks-primary__item" key={navLink.tipo} onClick={() => onMenuItemClicked(navLink.tipo)}>
              {navLink.title.toUpperCase()}
            </li>
          );
        })}
      </ul>

      <ul className="navlinks-secondary__container">
        <li>BEM-VINDO, {user && user[0]?.nome?.toUpperCase()}</li>
        <li>
          <Link to="/lgpd" style={{ display: "flex", alignItems: "center" }}>
            LGPD <i className="fas fa-edit" style={{ marginLeft: 8 }}></i>
          </Link>
        </li>
        <li>
          <button onClick={logout} className="navlinks-secondary__button">
            SAIR
          </button>
        </li>
      </ul>
    </>
  );

  const deslogados = (
    <ul className="navlinks-primary__container">
      <li className="navlinks-primary__item">
        <Link to="/login">Login</Link>
      </li>
      <li className="navlinks-primary__item">
        <Link to="/trial">
          Requisite seu TRIAL!  <i className="fas fa-edit"></i>
        </Link>
      </li>

      <li className="navlinks-primary__item">
        <Link to="/lgpd">
          Remoção de dados pessoais  <i className="fas fa-edit"></i>
        </Link>
      </li>
    </ul>
  );

  const admin = (
    <ul className="navlinks-primary__container">
      <li className="navlinks-primary__item">Bem-Vindo, {user && user[0]?.nome}</li>
      <li className="navlinks-primary__item">
        <Link to="/registrar">
          Cadastrar <i className="fas fa-edit"></i>
        </Link>
      </li>
      <li className="navlinks-primary__item">
        <Link to="/trial">
          Requisite seu TRIAL! <i className="fas fa-edit"></i>
        </Link>
      </li>

      <li className="navlinks-primary__item">
        <a onClick={logout} href="#!">
          Sair <i className="fas fa-sign-out-alt"></i>
        </a>
      </li>
    </ul>
  );

  if (user) {
    if (user[0]?.email !== undefined && user[0]?.email !== "") {
      if (user[0]?.email === "leandro@datafinder.com.br") {
        mostrar = admin;
      } else if (user[0]?.email === "leandro@datafinder.com.br") {
        mostrar = admin;
      } else {
        mostrar = logados;
      }
    }
  } else {
    mostrar = logados;
  }

  return (
    <header className="inner header-container">
      <Link to="/">
        <img src={logo} alt="Datafinder 2023" />
      </Link>

      <OutsideClickHandler onOutsideClick={() => {
        setMenuMobileOpen(false);
      }}>
        <div className="burger-menu" onClick={() => setMenuMobileOpen(!menuMobileOpen)}>
          <hr />
          <hr />
          <hr />
        </div>

        <div className={`navbar ${menuMobileOpen && 'navbar-open'}`}>
          <button className="mobile-close-btn" onClick={() => setMenuMobileOpen(false)}>X</button>
          <nav>{!loading && <Fragment>{isAuthenticated ? mostrar : deslogados}</Fragment>}</nav>
        </div>
      </OutsideClickHandler>
    </header>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
